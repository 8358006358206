//GeneratorFlag:[NoChange]
export default {
	path: 'payment-provider',
	name: 'finance.paymentProvider.layout',
	redirect: to => {
		return {name: 'finance.paymentProvider.list'};
	},
	component: () => import('@/modules/finance/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/finance/payment-provider"],
		resourceKey: 'finance.paymentProviderLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PaymentProviderCreate]"
		{
			path: 'create',
			name: 'finance.paymentProvider.create',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderCreate',
				clientAccessKeys:["/finance/payment-provider/create"],
				serverAccessKeys:["/finance/payment-provider/create","/finance/payment-provider/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PaymentProviderCreateImport]"
		{
			path: 'create-import',
			name: 'finance.paymentProvider.createImport',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderCreateImport',
				clientAccessKeys:["/finance/payment-provider/create-import"],
				serverAccessKeys:["/finance/payment-provider/create","/finance/payment-provider/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PaymentProviderDelete]"
		{
			path: 'delete',
			name: 'finance.paymentProvider.delete',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderDelete',
				clientAccessKeys:["/finance/payment-provider/delete"],
				serverAccessKeys:["/finance/payment-provider/delete","/finance/payment-provider/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PaymentProviderDetail]"
		{
			path: 'detail',
			name: 'finance.paymentProvider.detail',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderDetail',
				clientAccessKeys:["/finance/payment-provider/detail"],
				serverAccessKeys:["/finance/payment-provider/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PaymentProviderList]"
		{
			path: 'list',
			name: 'finance.paymentProvider.list',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderList',
				clientAccessKeys:["/finance/payment-provider/list"],
				serverAccessKeys:["/finance/payment-provider/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PaymentProviderAttachment]"
		{
			path: 'attachment',
			name: 'finance.paymentProvider.attachment',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderAttachment',
				clientAccessKeys:["/finance/payment-provider/attachment"],
				serverAccessKeys:["/finance/payment-provider/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PaymentProviderUpdate]"
		{
			path: 'update',
			name: 'finance.paymentProvider.update',
			component: () => import('@/modules/finance/views/payment-provider/payment-provider-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.paymentProviderUpdate',
				clientAccessKeys:["/finance/payment-provider/update"],
				serverAccessKeys:["/finance/payment-provider/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}