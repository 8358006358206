import { ENUMS } from '@/core/data'
import { PaymentProvider } from '@/modules/finance/data/payment-provider/create/'
export default class GoogleBillingProvider extends PaymentProvider
{
	constructor(arg)
	{
		super();

		delete this.props.jsonConfig;
		delete this.props.providerKey;

		this.props = {
			...this.props,
			type: {
				name: 'type',
				resourceKey: 'finance.ext.provider.googleBilling.type',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			project_id: {
				name: 'project_id',
				resourceKey: 'finance.ext.provider.googleBilling.project_id',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			package_name: {
				name: 'package_name',
				resourceKey: 'finance.ext.provider.googleBilling.package_name',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			private_key_id: {
				name: 'private_key_id',
				resourceKey: 'finance.ext.provider.googleBilling.private_key_id',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			private_key: {
				name: 'private_key',
				resourceKey: 'finance.ext.provider.googleBilling.private_key',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			client_email: {
				name: 'client_email',
				resourceKey: 'finance.ext.provider.googleBilling.client_email',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			client_id: {
				name: 'client_id',
				resourceKey: 'finance.ext.provider.googleBilling.client_id',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			auth_uri: {
				name: 'auth_uri',
				resourceKey: 'finance.ext.provider.googleBilling.auth_uri',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			token_uri: {
				name: 'token_uri',
				resourceKey: 'finance.ext.provider.googleBilling.token_uri',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			auth_provider_x509_cert_url: {
				name: 'auth_provider_x509_cert_url',
				resourceKey: 'finance.ext.provider.googleBilling.auth_provider_x509_cert_url',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			client_x509_cert_url: {
				name: 'client_x509_cert_url',
				resourceKey: 'finance.ext.provider.googleBilling.client_x509_cert_url',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			universe_domain: {
				name: 'universe_domain',
				resourceKey: 'finance.ext.provider.googleBilling.universe_domain',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
		}

		this.prepair(arg);
	}

	name = 'paymentProvider';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'finance.paymentProvider';
}
