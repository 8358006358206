//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"ai",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'bot':'Bot',
	'botAttachment':'Attach files',
	'botBotId':'bot id',
	'botBotName':'bot name',
	'botBotStatus':'status',
	'botBotTrack':'bot track',
	'botCaption':'caption',
	'botCreate':'Create New Bot',
	'botCreateImport':'Imports Bot From Excel',
	'botDelete':'Delete Bot',
	'botDetail':'Detail of Bot',
	'botIdentifier':'identifier',
	'botImageAttachment':'Attach images',
	'botIsActive':'is active',
	'botJsonConfig':'json config',
	'botLayout':'Bot',
	'botList':'List of Bot',
	'botServiceName':'service name',
	'botUpdate':'Edit Bot',
	'calculateTypeCalculateTypeId':'calculate type id',
	'calculateTypeCaption':'caption',
	'calculateTypeTitle':'title',
	'discount':'Discount',
	'discountAttachment':'Attach files',
	'discountCouponCode':'coupon code',
	'discountCreate':'Create New Discount',
	'discountCreateImport':'Imports Discount From Excel',
	'discountDelete':'Delete Discount',
	'discountDetail':'Detail of Discount',
	'discountDiscountAmount':'discount amount',
	'discountDiscountId':'discount id',
	'discountDiscountPercentage':'discount percentage',
	'discountDiscountTrack':'discount track',
	'discountEndUtcDate':'end utc date',
	'discountIdentifier':'identifier',
	'discountLayout':'Discount',
	'discountList':'List of Discount',
	'discountStartUtcDate':'start utc date',
	'discountTitle':'title',
	'discountUpdate':'Edit Discount',
	'discountUsageLimit':'usage limit',
	'package':'Package',
	'packageAttachment':'Attach files',
	'packageCalculateType':'calculate type',
	'packageCalculateTypeId':'calculate type',
	'packageCaption':'caption',
	'packageCreate':'Create New Package',
	'packageCreateImport':'Imports Package From Excel',
	'packageDayCount':'day count',
	'packageDelete':'Delete Package',
	'packageDetail':'Detail of Package',
	'packageDiscount':'discount',
	'packageDiscountId':'discount',
	'packageDocumentCount':'document count',
	'packageFileCount':'file count',
	'packageIdentifier':'identifier',
	'packageImageCount':'image count',
	'packageIsActive':'is active',
	'packageLanguagesModels':'languages models',
	'packageLayout':'Package',
	'packageLimitationUsage':'limitation usage',
	'packageList':'List of Package',
	'packagePackageId':'package id',
	'packagePackageTrack':'package track',
	'packagePrice':'price',
	'packageProductKey':'product key',
	'packageTitle':'title',
	'packageUpdate':'Edit Package',
	'prompt':'Prompt',
	'promptAttachment':'Attach files',
	'promptCreate':'Create New Prompt',
	'promptCreateImport':'Imports Prompt From Excel',
	'promptDelete':'Delete Prompt',
	'promptDetail':'Detail of Prompt',
	'promptGroup':'PromptGroup',
	'promptGroupAttachment':'Attach files',
	'promptGroupCaption':'caption',
	'promptGroupCreate':'Create New PromptGroup',
	'promptGroupCreateImport':'Imports PromptGroup From Excel',
	'promptGroupDelete':'Delete PromptGroup',
	'promptGroupDetail':'Detail of PromptGroup',
	'promptGroupHexColor':'hex color',
	'promptGroupIdentifier':'identifier',
	'promptGroupImageAttachment':'Attach images',
	'promptGroupLayout':'PromptGroup',
	'promptGroupList':'List of PromptGroup',
	'promptGroupPromptGroupId':'prompt group id',
	'promptGroupPromptGroupTrack':'prompt group track',
	'promptGroupTitle':'title',
	'promptGroupUpdate':'Edit PromptGroup',
	'promptIdentifier':'identifier',
	'promptImageAttachment':'Attach images',
	'promptIsActive':'is active',
	'promptLayout':'Prompt',
	'promptList':'List of Prompt',
	'promptPromptGroup':'prompt group',
	'promptPromptGroupId':'prompt group',
	'promptPromptId':'prompt id',
	'promptPromptTrack':'prompt track',
	'promptStringContent':'string content',
	'promptUpdate':'Edit Prompt',

}