//GeneratorFlag:[NoChange]
import blogContentRoute from './blog-content-route'; //GeneratorFlag:[BlogContent]
import staticContentRoute from './static-content-route'; //GeneratorFlag:[StaticContent]
import staticPageRoute from './static-page-route'; //GeneratorFlag:[StaticPage]
import basisBlogCategoryRoute from './basis/blog-category-route'; //GeneratorFlag:[BasisBlogCategory]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'cms',
	name: 'cms',
	component: () => import('@/modules/cms/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/cms'],
		serverAccessKeys:[],
		resourceKey: 'service.cms',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-vector-pen',
		}
	},
	children: [
blogContentRoute, staticContentRoute, staticPageRoute, //GeneratorFlag:[NewChild]
		//GeneratorFlag:[BasisLayout]
		{
			path: 'basis',
			name: 'cms.basis',
			component: () => import('@/modules/cms/views/layout.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'cms.layout.basis',
				clientAccessKeys:['/cms/basis'],
				serverAccessKeys:[],
				menuConfig: {
					isVisible: true,
					isBlank: true,
					iconName: 'bi-database-fill-gear',
				}
			},
			children: [
			basisBlogCategoryRoute, //GeneratorFlag:[NewBasisChild]
			]
		},
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
blogContentRoute, staticContentRoute, staticPageRoute, basisBlogCategoryRoute, //GeneratorFlag:[NewExport]
}