//GeneratorFlag:[NoChange]
export default {
	path: 'financial-document',
	name: 'finance.financialDocument.layout',
	redirect: to => {
		return {name: 'finance.financialDocument.list'};
	},
	component: () => import('@/modules/finance/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/finance/financial-document"],
		resourceKey: 'finance.financialDocumentLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[FinancialDocumentCreate]"
		{
			path: 'create',
			name: 'finance.financialDocument.create',
			component: () => import('@/modules/finance/views/financial-document/financial-document-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentCreate',
				clientAccessKeys:["/finance/financial-document/create"],
				serverAccessKeys:["/finance/financial-document/create","/finance/financial-document/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FinancialDocumentCreateImport]"
		{
			path: 'create-import',
			name: 'finance.financialDocument.createImport',
			component: () => import('@/modules/finance/views/financial-document/financial-document-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentCreateImport',
				clientAccessKeys:["/finance/financial-document/create-import"],
				serverAccessKeys:["/finance/financial-document/create","/finance/financial-document/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FinancialDocumentDelete]"
		{
			path: 'delete',
			name: 'finance.financialDocument.delete',
			component: () => import('@/modules/finance/views/financial-document/financial-document-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentDelete',
				clientAccessKeys:["/finance/financial-document/delete"],
				serverAccessKeys:["/finance/financial-document/delete","/finance/financial-document/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FinancialDocumentDetail]"
		{
			path: 'detail',
			name: 'finance.financialDocument.detail',
			component: () => import('@/modules/finance/views/financial-document/financial-document-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentDetail',
				clientAccessKeys:["/finance/financial-document/detail"],
				serverAccessKeys:["/finance/financial-document/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FinancialDocumentList]"
		{
			path: 'list',
			name: 'finance.financialDocument.list',
			component: () => import('@/modules/finance/views/financial-document/financial-document-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentList',
				clientAccessKeys:["/finance/financial-document/list"],
				serverAccessKeys:["/finance/financial-document/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FinancialDocumentAttachment]"
		{
			path: 'attachment',
			name: 'finance.financialDocument.attachment',
			component: () => import('@/modules/finance/views/financial-document/financial-document-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentAttachment',
				clientAccessKeys:["/finance/financial-document/attachment"],
				serverAccessKeys:["/finance/financial-document/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[FinancialDocumentUpdate]"
		{
			path: 'update',
			name: 'finance.financialDocument.update',
			component: () => import('@/modules/finance/views/financial-document/financial-document-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'finance.financialDocumentUpdate',
				clientAccessKeys:["/finance/financial-document/update"],
				serverAccessKeys:["/finance/financial-document/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}