//GeneratorFlag:[NoChange]
export default {
	path: 'prompt',
	name: 'ai.prompt.layout',
	redirect: to => {
		return {name: 'ai.prompt.list'};
	},
	component: () => import('@/modules/ai/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ai/prompt"],
		resourceKey: 'ai.promptLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PromptCreate]"
		{
			path: 'create',
			name: 'ai.prompt.create',
			component: () => import('@/modules/ai/views/prompt/prompt-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptCreate',
				clientAccessKeys:["/ai/prompt/create"],
				serverAccessKeys:["/ai/prompt/create","/ai/prompt/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptCreateImport]"
		{
			path: 'create-import',
			name: 'ai.prompt.createImport',
			component: () => import('@/modules/ai/views/prompt/prompt-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptCreateImport',
				clientAccessKeys:["/ai/prompt/create-import"],
				serverAccessKeys:["/ai/prompt/create","/ai/prompt/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptDelete]"
		{
			path: 'delete',
			name: 'ai.prompt.delete',
			component: () => import('@/modules/ai/views/prompt/prompt-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptDelete',
				clientAccessKeys:["/ai/prompt/delete"],
				serverAccessKeys:["/ai/prompt/delete","/ai/prompt/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptDetail]"
		{
			path: 'detail',
			name: 'ai.prompt.detail',
			component: () => import('@/modules/ai/views/prompt/prompt-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptDetail',
				clientAccessKeys:["/ai/prompt/detail"],
				serverAccessKeys:["/ai/prompt/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptList]"
		{
			path: 'list',
			name: 'ai.prompt.list',
			component: () => import('@/modules/ai/views/prompt/prompt-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptList',
				clientAccessKeys:["/ai/prompt/list"],
				serverAccessKeys:["/ai/prompt/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptAttachment]"
		{
			path: 'attachment',
			name: 'ai.prompt.attachment',
			component: () => import('@/modules/ai/views/prompt/prompt-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptAttachment',
				clientAccessKeys:["/ai/prompt/attachment"],
				serverAccessKeys:["/ai/prompt/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptImageAttachment]"
		{
			path: 'image-attachment',
			name: 'ai.prompt.imageAttachment',
			component: () => import('@/modules/ai/views/prompt/prompt-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptImageAttachment',
				clientAccessKeys:["/ai/prompt/image-attachment"],
				serverAccessKeys:["/ai/prompt/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptUpdate]"
		{
			path: 'update',
			name: 'ai.prompt.update',
			component: () => import('@/modules/ai/views/prompt/prompt-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptUpdate',
				clientAccessKeys:["/ai/prompt/update"],
				serverAccessKeys:["/ai/prompt/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}