<template>
	<date-picker :auto-submit="true"
				 :locale="locale"
				 :locale-config="localeConfig"
				 v-bind:dir="direction"
				 input-class="form-control"
				 :range="useRange"
				 :simple="!useRange"
				 v-bind:id="prop.id"
				 v-model="value"
				 v-on:localeChange="onHandelLocaleChange"
				 v-on:change="onHandelChange"
				 v-on:keyup="onHandelKeyUp" />
</template>
<script>
	export default {
		emits: ['change', 'keyup', 'submit'],

		props: {
			prop: {
				type: Object
			},

			'languageDirection': {
				type: String,
				default: undefined
			},
		},

		data()
		{
			return {
				value: null,
				curentLocal: this.language
			}
		},

		computed:
		{
			language()
			{
				return this.$store.state.app.language;
			},

			direction()
			{
				if (this.$store.state.app.direction == 'ltr' || this.languageDirection == 'ltr')
				{
					return 'ltr'
				}

				return this.prop.display.direction;
			},


			localeConfig()
			{
				return {
					fa: {
						displayFormat: 'jYYYY/jMM/jDD',
						lang: { label: 'Persian' }
					},
					en: {
						displayFormat: 'YYYY/MM/DD',
						lang: { label: 'Gregorian' }
					}
				}
			},

			isUtc()
			{
				return this.prop.name.toLowerCase().includes('utc')
			},

			locale()
			{
				return this.language == 'fa' ? 'fa,en' : 'en,fa';
			},

			useRange()
			{
				return this.$parent.mode == "filter"
			}
		},

		beforeMount()
		{
			this.value = this.prop.value
		},

		methods: {

			format(e)
			{
				var event = e
				var value = null;
				var propName = this.prop.name.toLowerCase();
				if (propName.includes('utc'))
				{
					event = e.utc();
				}

				return event.format('YYYY/MM/DD')
			},

			getValue(e)
			{
				var value = e
				if (Array.isArray(value))
				{
					value = `${this.format(value[0])}|${this.format(value[1])}`
				}

				return this.format(value)
			},

			onHandelChange(e)
			{
				this.$emit('change', this.getValue(e))
			},

			onHandelKeyUp(e)
			{
				if (e.key === 'Enter' || e.keyCode === 13)
				{
					this.$emit('submit')
				}
				else
				{
					this.$emit('keyup', this.getValue())
				}
			},

			onHandelLocaleChange(e)
			{
				this.curentLocal = e.name
			}
		}
	}
</script>
<style>
	[dir="ltr"] .vpd-input-group input {
		border-radius: 0 4px 4px 0 !important;
		border-right: #ddd solid 1px;
	}
</style>
