//GeneratorFlag:[NoChange]
import routes from './routes'
import services from './services'
import resources from './resources'
import toolbar from './toolbar'

const finance = {
	name: 'finance',
	foldreName: 'finance',
	routes,
	services,
	resources,
	toolbar
}

export default finance
export { routes, services, resources, toolbar }