<template>
	<kaveh-table :key="ver" :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { toast } from '@/plugins'
	import { Bot, BotFilter } from '@/modules/ai/data/bot/list'
	import { BotService } from '@/modules/ai/services'
	export default {
		data()
		{
			return {
				ver:0
			}
		},

		computed:
		{
			entity()
			{
				return Bot;
			},

			filterEntity()
			{
				return BotFilter;
			},

			service()
			{
				return new BotService();
			},

			actions()
			{
				return [
					{
						routeName: 'ai.bot.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'ai.bot.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'ai.bot.imageAttachment',
						resourceKey: 'action.imageAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						resourceKey: 'action.changeStatus',
						iconName: 'bi-lightbulb',
						activeMode: 'single',
						task: async (x) =>
						{
							const res = await this.service.changeStatus({
								botId: x[0].botId
							}, { HSR: false });

							if (res.status == 200)
							{
								this.ver += 1;
								toast.info(null, res.data.message)
							}
						}
					},

					{
						routeName: 'ai.bot.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'ai.bot.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'ai.bot.detail',
							resourceKey: 'ai.botDetail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
