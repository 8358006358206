//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"notification",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'noticePattern':'NoticePattern',
	'noticePatternCreate':'Create New NoticePattern',
	'noticePatternCreateImport':'Imports NoticePattern From Excel',
	'noticePatternDelete':'Delete NoticePattern',
	'noticePatternDetail':'Detail of NoticePattern',
	'noticePatternJsonConfig':'json config',
	'noticePatternLayout':'NoticePattern',
	'noticePatternList':'List of NoticePattern',
	'noticePatternNoticePatternId':'notice pattern id',
	'noticePatternNoticePatternTrack':'notice pattern track',
	'noticePatternNoticeType':'notice type',
	'noticePatternNoticeTypeId':'notice type',
	'noticePatternNotificationEventKey':'notification event key',
	'noticePatternPatternBody':'pattern body',
	'noticePatternPatternKey':'pattern key',
	'noticePatternReceiverPerson':'receiver person',
	'noticePatternReceiverPersonId':'receiver person',
	'noticePatternTitle':'title',
	'noticePatternUpdate':'Edit NoticePattern',
	'noticeTypeCaption':'caption',
	'noticeTypeNoticeTypeId':'notice type id',
	'noticeTypeTitle':'title',
	'notificationPersonIdentifier':'identifier',
	'notificationPersonPersonId':'person id',
	'notificationPersonPersonName':'person name',

}