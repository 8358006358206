//GeneratorFlag:[NoChange]
import NoticePatternService from './notice-pattern-service'; //GeneratorFlag:[NoticePattern]
import NoticeStatusService from './notice-status-service'; //GeneratorFlag:[NoticeStatus]
import NoticeTypeService from './notice-type-service'; //GeneratorFlag:[NoticeType]
import NoticeService from './notice-service'; //GeneratorFlag:[Notice]
import OtpService from './otp-service'; //GeneratorFlag:[Otp]
import PushNotificationDeviceService from './push-notification-device-service'; //GeneratorFlag:[PushNotificationDevice]
//GeneratorFlag:[NewImport]
export default {
NoticePatternService, //GeneratorFlag:[NoticePattern]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
NoticeTypeService, //GeneratorFlag:[NoticeType]
NoticeService, //GeneratorFlag:[Notice]
OtpService, //GeneratorFlag:[Otp]
PushNotificationDeviceService, //GeneratorFlag:[PushNotificationDevice]
//GeneratorFlag:[NewExport]
}
export{
NoticePatternService, //GeneratorFlag:[NoticePattern]
NoticeStatusService, //GeneratorFlag:[NoticeStatus]
NoticeTypeService, //GeneratorFlag:[NoticeType]
NoticeService, //GeneratorFlag:[Notice]
OtpService, //GeneratorFlag:[Otp]
PushNotificationDeviceService, //GeneratorFlag:[PushNotificationDevice]
//GeneratorFlag:[NewExport]
}
