//GeneratorFlag:[NoChange]
export default {
	path: 'prompt-group',
	name: 'ai.promptGroup.layout',
	redirect: to => {
		return {name: 'ai.promptGroup.list'};
	},
	component: () => import('@/modules/ai/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ai/prompt-group"],
		resourceKey: 'ai.promptGroupLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PromptGroupCreate]"
		{
			path: 'create',
			name: 'ai.promptGroup.create',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupCreate',
				clientAccessKeys:["/ai/prompt-group/create"],
				serverAccessKeys:["/ai/prompt-group/create","/ai/prompt-group/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupCreateImport]"
		{
			path: 'create-import',
			name: 'ai.promptGroup.createImport',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupCreateImport',
				clientAccessKeys:["/ai/prompt-group/create-import"],
				serverAccessKeys:["/ai/prompt-group/create","/ai/prompt-group/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupDelete]"
		{
			path: 'delete',
			name: 'ai.promptGroup.delete',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupDelete',
				clientAccessKeys:["/ai/prompt-group/delete"],
				serverAccessKeys:["/ai/prompt-group/delete","/ai/prompt-group/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupDetail]"
		{
			path: 'detail',
			name: 'ai.promptGroup.detail',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupDetail',
				clientAccessKeys:["/ai/prompt-group/detail"],
				serverAccessKeys:["/ai/prompt-group/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupList]"
		{
			path: 'list',
			name: 'ai.promptGroup.list',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupList',
				clientAccessKeys:["/ai/prompt-group/list"],
				serverAccessKeys:["/ai/prompt-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupAttachment]"
		{
			path: 'attachment',
			name: 'ai.promptGroup.attachment',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupAttachment',
				clientAccessKeys:["/ai/prompt-group/attachment"],
				serverAccessKeys:["/ai/prompt-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupImageAttachment]"
		{
			path: 'image-attachment',
			name: 'ai.promptGroup.imageAttachment',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupImageAttachment',
				clientAccessKeys:["/ai/prompt-group/image-attachment"],
				serverAccessKeys:["/ai/prompt-group/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PromptGroupUpdate]"
		{
			path: 'update',
			name: 'ai.promptGroup.update',
			component: () => import('@/modules/ai/views/prompt-group/prompt-group-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.promptGroupUpdate',
				clientAccessKeys:["/ai/prompt-group/update"],
				serverAccessKeys:["/ai/prompt-group/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}