//GeneratorFlag:[NoChange]
import financialDocumentRoute from './financial-document-route'; //GeneratorFlag:[FinancialDocument]
import paymentProviderRoute from './payment-provider-route'; //GeneratorFlag:[PaymentProvider]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'finance',
	name: 'finance',
	component: () => import('@/modules/finance/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/finance'],
		serverAccessKeys:[],
		resourceKey: 'service.finance',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-cash-coin',
		}
	},
	children: [
financialDocumentRoute, paymentProviderRoute, //GeneratorFlag:[NewChild]
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
financialDocumentRoute, paymentProviderRoute, //GeneratorFlag:[NewExport]
}