<template>
	<v-select dir="rtl"
			  v-model="value"
			  :id="prop.id"
			  :loading="loading"
			  :reduce="o => o.value"
			  :options="options"
			  :clearable="false"
			  label="text"
			  :multiple="prop.setConfig.isMultiple"
			  @search="searchChange"
			  @option:selected="onHandelChange">
		<template v-slot:no-options>
			{{$loc.fromResource('com.form.select.noOption')}}
		</template>
		<template #option="{ text, caption }">
			<span>{{text}}</span><small v-if="caption" class="text-muted">&nbsp; - &nbsp;{{caption}} </small>  
		</template>
		<template #selected-option="{ text, caption }">
			<span>{{text}}</span><small v-if="caption" class="text-muted">&nbsp; - &nbsp;{{caption}} </small>  
		</template>
	</v-select>
</template>
<script>
	import vSelect from "vue-select";
	import "vue-select/dist/vue-select.css";
	import './form-select.css'
	import SelectService from './select-service'

	export default {
		emits: ['change', 'keyup', 'update:modelValue'],
		components: {
			'v-select': vSelect
		},

		props: {
			prop: {
				type: Object
			}
		},

		data()
		{
			return {
				query: '',
				value: null,
				options: [],
				loading: false,
			}
		},

		async beforeMount()
		{
			await this.getOptions()
			this.value = this.prop.value
		},

		methods: {

			async getOptions()
			{
				var options = []
				var prop = this.prop;
				var textKey = this.prop.setConfig.textKey;
				var parentProperty = this.prop.parentProperty;

				var params = {
					start: 0, length: 50, filter: {}
				}

				if (this.query)
				{
					params.filter[textKey] = this.query
				}


				if (parentProperty)
				{
					if (!parentProperty.value)
					{
						this.options = options
						return;
					}

					params.filter[parentProperty.name] = parentProperty.value
				}


				try
				{
					var items = [];
					this.loading = true;
					if (Array.isArray(prop.data) && prop.data.length > 0)
					{
						items = prop.data;
					}
					else if (typeof (prop.data) == 'function')
					{
						const res = await prop.data(params)
						items = this.$loc.fromList(res.data.items || [])
					}
					else if (prop.serverAddress)
					{
						const res = await new SelectService().get(params, prop.serverAddress)
						items = this.$loc.fromList(res.data.items || [])
					}


					if (items)
					{
						options.push({
							text: '-',
							value: null,
							caption: ''
						})

						for (var item of items)
						{
							options.push({
								text: item[prop.setConfig.textKey],
								value: item[prop.setConfig.valueKey],
								caption: item.caption
							})
						}
					}

				} catch (e)
				{
					console.log('form-select', e)
				}

				this.loading = false;
				this.options = options
			},

			async searchChange(query)
			{
				if (query != "")
				{
					this.query = query
					await this.getOptions()

					this.value = null
					this.onHandelChange(null)
				}
			},

			onHandelChange(payload)
			{
				if (payload)
				{
					var _value;
					if (payload.length != undefined)
					{
						var _value = [];
						for (var i = 0; i < payload.length; i++)
						{
							_value.push(payload[i].value)
						}
					}
					else
					{
						_value = payload.value
					}

					this.$emit('update:modelValue', _value, _value == null);
					this.$emit('change', _value, _value == null)
				}
			},
		},

		async mounted()
		{
			var self = this
			self.$emitter.on('formChange', async (prop) =>
			{
				var parentProperty = this.prop.parentProperty;
				if (parentProperty && prop.name == parentProperty.name)
				{
					this.value = null
					this.onHandelChange(null)
					await this.getOptions()
				}
			})
		}
	}
</script>
