<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { PaymentProvider, PaymentProviderFilter } from '@/modules/finance/data/payment-provider/list'
	import { PaymentProviderService } from '@/modules/finance/services'
	export default {
		computed:
		{
			entity()
			{
				return PaymentProvider;
			},
			
			filterEntity()
			{
				return PaymentProviderFilter;
			},

			service()
			{
				return new PaymentProviderService();
			},

			actions()
			{
				return [
					{
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
						children: [
							{
								routeName: 'finance.paymentProvider.create',
								routeParams: { type: "beh-pardakht" },
								resourceKey: 'finance.ext.provider.behPardakht.self',
								iconName: 'bi-credit-card',
								activeMode: 'always',
							},

							{
								routeName: 'finance.paymentProvider.create',
								routeParams: { type: "google-billing" },
								resourceKey: 'finance.ext.provider.googleBilling.self',
								iconName: 'bi-credit-card',
								activeMode: 'always',
							},
						]
					},

					{
						routeName: 'finance.paymentProvider.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'finance.paymentProvider.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'finance.paymentProvider.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'finance.paymentProvider.detail',
							resourceKey: 'finance.paymentProviderDetail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>
