//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"finance",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'financePersonIdentifier':'کلید یکتا',
	'financePersonPersonId':'شناسه شخص',
	'financePersonPersonName':'نام شخص',
	'financialDocument':'سند مالی',
	'financialDocumentAmount':'مبلغ',
	'financialDocumentAttachment':'پیوست فایل',
	'financialDocumentComment':'توضیحات',
	'financialDocumentCreate':'ثبت سند مالی جدید',
	'financialDocumentCreateImport':'ثبت اسناد مالی از طریق اکسل',
	'financialDocumentCurrencyUnit':'واحد پول',
	'financialDocumentDelete':'حذف سند مالی',
	'financialDocumentDetail':'جزئیات اطلاعات سند مالی',
	'financialDocumentFinancialDocumentId':'شناسه',
	'financialDocumentFinancialDocumentTrack':'اطلاعات رهگیری ثبت',
	'financialDocumentIdentifier':'کلید یکتا',
	'financialDocumentIsDeposit':'سند واریز است؟',
	'financialDocumentLayout':'اسناد مالی',
	'financialDocumentList':'فهرست اسناد مالی',
	'financialDocumentOriginAccount':'حساب یا شماره مبدا',
	'financialDocumentPerson':'شناسه شخص',
	'financialDocumentPersonId':'شناسه شخص',
	'financialDocumentTrackingCode':'کد رهگیری',
	'financialDocumentTransactionUtcDate':'تاریخ ثبت جهانی',
	'financialDocumentTransactionUtcTime':'زمان ثبت جهانی',
	'financialDocumentTransferMethod':'نحوه انتقال',
	'financialDocumentTransferMethodId':'نحوه انتقال',
	'financialDocumentUpdate':'ویرایش سند مالی',
	'paymentProvider':'سرویس پرداخت',
	'paymentProviderAttachment':'پیوست فایل',
	'paymentProviderCreate':'ثبت سرویس پرداخت جدید',
	'paymentProviderCreateImport':'ثبت سرویس های پرداخت از طریق اکسل',
	'paymentProviderDelete':'حذف سرویس پرداخت',
	'paymentProviderDetail':'جزئیات اطلاعات سرویس پرداخت',
	'paymentProviderIdentifier':'کلید یکتا',
	'paymentProviderJsonConfig':'کانفیگ برنامه نویس',
	'paymentProviderLayout':'سرویس های پرداخت',
	'paymentProviderList':'فهرست سرویس های پرداخت',
	'paymentProviderPaymentProviderId':'شناسه',
	'paymentProviderPaymentProviderTrack':'اطلاعات رهگیری ثبت',
	'paymentProviderProviderKey':'کلید اتصال',
	'paymentProviderTitle':'عنوان',
	'paymentProviderUpdate':'ویرایش سرویس پرداخت',
	'transferMethodCaption':'توضیحات',
	'transferMethodTitle':'عنوان',
	'transferMethodTransferMethodId':'شناسه',

}