//GeneratorFlag:[NoChange]
export default {
	path: 'package',
	name: 'ai.package.layout',
	redirect: to => {
		return {name: 'ai.package.list'};
	},
	component: () => import('@/modules/ai/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ai/package"],
		resourceKey: 'ai.packageLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[PackageCreate]"
		{
			path: 'create',
			name: 'ai.package.create',
			component: () => import('@/modules/ai/views/package/package-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageCreate',
				clientAccessKeys:["/ai/package/create"],
				serverAccessKeys:["/ai/package/create","/ai/package/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PackageCreateImport]"
		{
			path: 'create-import',
			name: 'ai.package.createImport',
			component: () => import('@/modules/ai/views/package/package-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageCreateImport',
				clientAccessKeys:["/ai/package/create-import"],
				serverAccessKeys:["/ai/package/create","/ai/package/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PackageDelete]"
		{
			path: 'delete',
			name: 'ai.package.delete',
			component: () => import('@/modules/ai/views/package/package-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageDelete',
				clientAccessKeys:["/ai/package/delete"],
				serverAccessKeys:["/ai/package/delete","/ai/package/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PackageDetail]"
		{
			path: 'detail',
			name: 'ai.package.detail',
			component: () => import('@/modules/ai/views/package/package-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageDetail',
				clientAccessKeys:["/ai/package/detail"],
				serverAccessKeys:["/ai/package/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PackageList]"
		{
			path: 'list',
			name: 'ai.package.list',
			component: () => import('@/modules/ai/views/package/package-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageList',
				clientAccessKeys:["/ai/package/list"],
				serverAccessKeys:["/ai/package/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PackageAttachment]"
		{
			path: 'attachment',
			name: 'ai.package.attachment',
			component: () => import('@/modules/ai/views/package/package-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageAttachment',
				clientAccessKeys:["/ai/package/attachment"],
				serverAccessKeys:["/ai/package/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[PackageUpdate]"
		{
			path: 'update',
			name: 'ai.package.update',
			component: () => import('@/modules/ai/views/package/package-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.packageUpdate',
				clientAccessKeys:["/ai/package/update"],
				serverAccessKeys:["/ai/package/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}