//GeneratorFlag:[NoChange]
import ext from './fa-ext.js'
export default {
	"name":"notification",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'noticePattern':'الگوی اعلان',
	'noticePatternCreate':'ثبت الگوی اعلان جدید',
	'noticePatternCreateImport':'ثبت الگوهای اعلان از طریق اکسل',
	'noticePatternDelete':'حذف الگوی اعلان',
	'noticePatternDetail':'جزئیات اطلاعات الگوی اعلان',
	'noticePatternJsonConfig':'کانفیگ',
	'noticePatternLayout':'الگوهای اعلان',
	'noticePatternList':'فهرست الگوهای اعلان',
	'noticePatternNoticePatternId':'شناسه',
	'noticePatternNoticePatternTrack':'اطلاعات رهگیری ثبت',
	'noticePatternNoticeType':'نوع اعلان',
	'noticePatternNoticeTypeId':'نوع اعلان',
	'noticePatternNotificationEventKey':'رخداد',
	'noticePatternPatternBody':'محتوا',
	'noticePatternPatternKey':'کلید',
	'noticePatternReceiverPerson':'گیرنده رخداد',
	'noticePatternReceiverPersonId':'گیرنده رخداد',
	'noticePatternTitle':'عنوان',
	'noticePatternUpdate':'ویرایش الگوی اعلان',
	'noticeTypeCaption':'توضیحات',
	'noticeTypeNoticeTypeId':'شناسه',
	'noticeTypeTitle':'عنوان',
	'notificationPersonIdentifier':'کلید یکتا',
	'notificationPersonPersonId':'شناسه شخص',
	'notificationPersonPersonName':'نام عضو',

}