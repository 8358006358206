//GeneratorFlag:[NoChange]
import ext from './en-ext.js'
export default {
	"name":"finance",
	"ext": ext,
	"layout":{
//GeneratorFlag:[NewLayout]
	},
	'financePersonIdentifier':'identifier',
	'financePersonPersonId':'person id',
	'financePersonPersonName':'person name',
	'financialDocument':'FinancialDocument',
	'financialDocumentAmount':'amount',
	'financialDocumentAttachment':'Attach files',
	'financialDocumentComment':'comment',
	'financialDocumentCreate':'Create New FinancialDocument',
	'financialDocumentCreateImport':'Imports FinancialDocument From Excel',
	'financialDocumentCurrencyUnit':'currency unit',
	'financialDocumentDelete':'Delete FinancialDocument',
	'financialDocumentDetail':'Detail of FinancialDocument',
	'financialDocumentFinancialDocumentId':'financial document id',
	'financialDocumentFinancialDocumentTrack':'financial document track',
	'financialDocumentIdentifier':'identifier',
	'financialDocumentIsDeposit':'is deposit',
	'financialDocumentLayout':'FinancialDocument',
	'financialDocumentList':'List of FinancialDocument',
	'financialDocumentOriginAccount':'origin account',
	'financialDocumentPerson':'person',
	'financialDocumentPersonId':'person',
	'financialDocumentTrackingCode':'tracking code',
	'financialDocumentTransactionUtcDate':'transaction utc date',
	'financialDocumentTransactionUtcTime':'transaction utc time',
	'financialDocumentTransferMethod':'transfer method',
	'financialDocumentTransferMethodId':'transfer method',
	'financialDocumentUpdate':'Edit FinancialDocument',
	'paymentProvider':'PaymentProvider',
	'paymentProviderAttachment':'Attach files',
	'paymentProviderCreate':'Create New PaymentProvider',
	'paymentProviderCreateImport':'Imports PaymentProvider From Excel',
	'paymentProviderDelete':'Delete PaymentProvider',
	'paymentProviderDetail':'Detail of PaymentProvider',
	'paymentProviderIdentifier':'identifier',
	'paymentProviderJsonConfig':'json config',
	'paymentProviderLayout':'PaymentProvider',
	'paymentProviderList':'List of PaymentProvider',
	'paymentProviderPaymentProviderId':'payment provider id',
	'paymentProviderPaymentProviderTrack':'payment provider track',
	'paymentProviderProviderKey':'provider key',
	'paymentProviderTitle':'title',
	'paymentProviderUpdate':'Edit PaymentProvider',
	'transferMethodCaption':'caption',
	'transferMethodTitle':'title',
	'transferMethodTransferMethodId':'transfer method id',

}