<template>
	<kaveh-content :loading="loading" width="1100px">
		<kaveh-form v-if="temp && entity" :key="ver" :entity="entity" :task="submit" :value="temp" />
	</kaveh-content>
</template>
<script>
	import { BehPardakhtProvider, GoogleBillingProvider } from './data/providers'
	import { PaymentProviderService } from '@/modules/finance/services'
	export default {
		data()
		{
			return {
				ver: 0,
				temp: null,
				loading: true
			}
		},
		computed:
		{
			entity()
			{
				switch (this.type)
				{
					case 'beh-pardakht':
						return BehPardakhtProvider
					case 'google-billing':
						return GoogleBillingProvider
					default:
						return undefined
				}
			},
			service()
			{
				return new PaymentProviderService()
			},
		},
		async beforeMount()
		{
			await this.init()
		},
		methods:
		{
			async init()
			{
				var value =
					await this.getDataFromService();

				if (value == undefined)
				{
					this.$router.go(-1)
				}

				this.temp = {
					...value,
					...JSON.parse(value.jsonConfig)
				}

				this.type = value.providerKey
			},
			async getDataFromService()
			{
				var result;
				const temp = await this.getDataFromTemp();

				if (temp)
				{
					try
					{
						this.loading = true;
						const payload = { 'paymentProviderId': temp['paymentProviderId'] };
						const res = await this.service.detail(payload, { HSR: false, HER: false });

						if (res.status == 200)
						{
							result = res.data;
						}

					} catch (e)
					{
						if (e.response && e.response.status == 404)
						{
							result = { 'paymentProviderId': temp['paymentProviderId'] }
						}
					}
				}

				this.loading = false;
				return result;
			},

			async getDataFromTemp()
			{
				const store = this.$store;
				const router = this.$route;
				const temp =
					await store.dispatch(
						'temp/findByQuery', router.query
					);

				return temp ? temp.value : undefined;
			},

			async submit(payload)
			{
				try
				{
					var editedPayload = {
						title: payload.title,
						currencyUnitId: payload.currencyUnitId,
						providerKey: this.type,
						paymentProviderId: this.temp.paymentProviderId,
						identifier: this.temp.identifier,
						jsonConfig: this.getJsonConfig(payload),
					}

					const res = await this.service.update(editedPayload);
					if (res.status == 200)
					{
						this.$router.go(-1)
					}

				} catch (e) { console.log(e) }
			},

			getJsonConfig(value)
			{
				var clone =
					JSON.parse(JSON.stringify(value))


				delete clone.title;
				delete clone.providerKey;
				delete clone.currencyUnitId;

				return JSON.stringify(clone, null, "\t");;
			},
		}
	}
</script>
