//GeneratorFlag:[NoChange]
import BotService from './bot-service'; //GeneratorFlag:[Bot]
import DiscountService from './discount-service'; //GeneratorFlag:[Discount]
import PackageService from './package-service'; //GeneratorFlag:[Package]
import PromptService from './prompt-service'; //GeneratorFlag:[Prompt]
import PromptGroupService from './prompt-group-service'; //GeneratorFlag:[PromptGroup]
import AssetUnitService from './asset-unit-service'; //GeneratorFlag:[AssetUnit]
import CalculateTypeService from './calculate-type-service'; //GeneratorFlag:[CalculateType]
import InvoiceService from './invoice-service'; //GeneratorFlag:[Invoice]
//GeneratorFlag:[NewImport]
export default {
BotService, //GeneratorFlag:[Bot]
DiscountService, //GeneratorFlag:[Discount]
PackageService, //GeneratorFlag:[Package]
PromptService, //GeneratorFlag:[Prompt]
PromptGroupService, //GeneratorFlag:[PromptGroup]
AssetUnitService, //GeneratorFlag:[AssetUnit]
CalculateTypeService, //GeneratorFlag:[CalculateType]
InvoiceService, //GeneratorFlag:[Invoice]
//GeneratorFlag:[NewExport]
}
export{
BotService, //GeneratorFlag:[Bot]
DiscountService, //GeneratorFlag:[Discount]
PackageService, //GeneratorFlag:[Package]
PromptService, //GeneratorFlag:[Prompt]
PromptGroupService, //GeneratorFlag:[PromptGroup]
AssetUnitService, //GeneratorFlag:[AssetUnit]
CalculateTypeService, //GeneratorFlag:[CalculateType]
InvoiceService, //GeneratorFlag:[Invoice]
//GeneratorFlag:[NewExport]
}