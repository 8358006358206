import { ENUMS } from '@/core/data'
import { PaymentProvider } from '@/modules/finance/data/payment-provider/create/'
export default class BehPardakhtProvider extends PaymentProvider
{
	constructor(arg)
	{
		super();

		delete this.props.jsonConfig;
		delete this.props.providerKey;

		this.props = {
			...this.props,
			terminalId: {
				name: 'terminalId',
				resourceKey: 'finance.ext.provider.behPardakht.terminalId',
				type: ENUMS.PROP_TYPE.INT64,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.NUMBER,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			userName: {
				name: 'userName',
				resourceKey: 'finance.ext.provider.behPardakht.userName',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			password: {
				name: 'password',
				resourceKey: 'finance.ext.provider.behPardakht.password',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
			callBackUrl: {
				name: 'callBackUrl',
				resourceKey: 'finance.ext.provider.behPardakht.callBackUrl',
				type: ENUMS.PROP_TYPE.STRING,
				display: {
					isVisible: true,
					displayType: ENUMS.DISPLAY_TYPE.STRING,
					direction: ENUMS.DIRECTION_TYPE.LTR,
				},
				setConfig: {
					inputType: ENUMS.INPUT_TYPE.TEXT,
					canUseInForm: true,
					isUnicode: false,
					stringLength: 100,
					patterns: ['required'],
				},
				errors: [],
				value: null
			},
		}

		this.prepair(arg);
	}

	name = 'paymentProvider';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'finance.paymentProvider';
}
