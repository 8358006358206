export default {

	provider: {

		behPardakht: {
			self:'درگاه به پرداخت ملت',
			terminalId:'شماره ترمینال',
			userName:'نام کاربری',
			password:'کلمه عبور',
			callBackUrl:'آدرس کال بک',
		},

		googleBilling: {
			self: 'درگاه پرداخت گوگل',
			type: 'type',
			project_id: 'project id',
			package_name: 'package name',
			private_key_id: 'private key id',
			private_key: 'private key',
			client_email: 'client email',
			client_id: 'client id',
			auth_uri: 'auth uri',
			token_uri: 'token uri',
			auth_provider_x509_cert_url: 'auth x509',
			client_x509_cert_url: 'client x509',
			universe_domain: 'universe domain',
		}
	}
}
