//GeneratorFlag:[NoChange]
import { ENUMS, BaseEntity, BaseTrack } from '@/core/data'
export default class Bot extends BaseEntity
{
	constructor(arg)
	{
		super();
		this.prepair(arg);
	}
	name = 'bot';
	schema = ENUMS.SCHEMA_TYPE.MAIN;
	resourceKey = 'ai.bot';
	props = {
		botId: {
			primarykey: true,
			name: 'botId',
			resourceKey: 'ai.botBotId',
			type: ENUMS.PROP_TYPE.INT32,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.NUMBER,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.NUMBER,
				canUseInForm: true,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		botName: {
			name: 'botName',
			resourceKey: 'ai.botBotName',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		serviceName: {
			name: 'serviceName',
			resourceKey: 'ai.botServiceName',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 50,
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		caption: {
			name: 'caption',
			resourceKey: 'ai.botCaption',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXTAREA,
				canUseInForm: false,  
				canUseInExcel: false,  
				stringLength: 150,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		isActive: {
			name: 'isActive',
			resourceKey: 'ai.botIsActive',
			type: ENUMS.PROP_TYPE.BOOLEAN,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.BOOLEAN,
				direction: ENUMS.DIRECTION_TYPE.LTR,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.CHECKDROP,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		jsonConfig: {
			name: 'jsonConfig',
			resourceKey: 'ai.botJsonConfig',
			type: ENUMS.PROP_TYPE.STRING,
			isMultiLanguages: true,
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.TEXT,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: ['required'],
			},
			errors: [],
			value: null
		},
		translations: {
			name: 'translations',
			resourceKey: 'ai.botTranslations',
			type: ENUMS.PROP_TYPE.STRING,
			display: {
				isVisible: false, 
				displayType: ENUMS.DISPLAY_TYPE.STRING,
				direction: ENUMS.DIRECTION_TYPE.RTL,
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.HIDDEN,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
		botTrack: {
			name: 'botTrack',
			resourceKey: 'ai.botBotTrack',
			type: ENUMS.PROP_TYPE.OBJECT,
			entity: new BaseTrack(),
			display: {
				isVisible: true, 
				displayType: ENUMS.DISPLAY_TYPE.POPUP,
				direction: ENUMS.DIRECTION_TYPE.RTL,
				defaultValue: 'data.track.summary',
			},
			setConfig: { 
				inputType: ENUMS.INPUT_TYPE.FORM,
				canUseInForm: false,  
				canUseInExcel: false,  
				isUnicode: false,
				isUnique: false,
				patterns: [],
			},
			errors: [],
			value: null
		},
	};
}