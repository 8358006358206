//GeneratorFlag:[NoChange]
import botRoute from './bot-route'; //GeneratorFlag:[Bot]
import discountRoute from './discount-route'; //GeneratorFlag:[Discount]
import packageRoute from './package-route'; //GeneratorFlag:[Package]
import promptRoute from './prompt-route'; //GeneratorFlag:[Prompt]
import promptGroupRoute from './prompt-group-route'; //GeneratorFlag:[PromptGroup]
//GeneratorFlag:[NewImport]
const routes = [{
	path: 'ai',
	name: 'ai',
	component: () => import('@/modules/ai/views/layout.vue'),
	meta: {
		requiresAuth: true,
		clientAccessKeys:['/ai'],
		serverAccessKeys:[],
		resourceKey: 'service.ai',
		menuConfig: {
			isVisible: true,
			isBlank: true,
			iconName: 'bi-robot',
		}
	},
	children: [
botRoute, discountRoute, packageRoute, promptRoute, promptGroupRoute, //GeneratorFlag:[NewChild]
//GeneratorFlag:[NewLayout]
	]
}]
export default routes
export{
botRoute, discountRoute, packageRoute, promptRoute, promptGroupRoute, //GeneratorFlag:[NewExport]
}