//GeneratorFlag:[NoChange]
export default {
	path: 'bot',
	name: 'ai.bot.layout',
	redirect: to => {
		return {name: 'ai.bot.list'};
	},
	component: () => import('@/modules/ai/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ai/bot"],
		resourceKey: 'ai.botLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[BotCreate]"
		{
			path: 'create',
			name: 'ai.bot.create',
			component: () => import('@/modules/ai/views/bot/bot-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botCreate',
				clientAccessKeys:["/ai/bot/create"],
				serverAccessKeys:["/ai/bot/create","/ai/bot/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotCreateImport]"
		{
			path: 'create-import',
			name: 'ai.bot.createImport',
			component: () => import('@/modules/ai/views/bot/bot-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botCreateImport',
				clientAccessKeys:["/ai/bot/create-import"],
				serverAccessKeys:["/ai/bot/create","/ai/bot/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotDelete]"
		{
			path: 'delete',
			name: 'ai.bot.delete',
			component: () => import('@/modules/ai/views/bot/bot-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botDelete',
				clientAccessKeys:["/ai/bot/delete"],
				serverAccessKeys:["/ai/bot/delete","/ai/bot/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotDetail]"
		{
			path: 'detail',
			name: 'ai.bot.detail',
			component: () => import('@/modules/ai/views/bot/bot-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botDetail',
				clientAccessKeys:["/ai/bot/detail"],
				serverAccessKeys:["/ai/bot/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotList]"
		{
			path: 'list',
			name: 'ai.bot.list',
			component: () => import('@/modules/ai/views/bot/bot-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botList',
				clientAccessKeys:["/ai/bot/list"],
				serverAccessKeys:["/ai/bot/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotAttachment]"
		{
			path: 'attachment',
			name: 'ai.bot.attachment',
			component: () => import('@/modules/ai/views/bot/bot-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botAttachment',
				clientAccessKeys:["/ai/bot/attachment"],
				serverAccessKeys:["/ai/bot/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotImageAttachment]"
		{
			path: 'image-attachment',
			name: 'ai.bot.imageAttachment',
			component: () => import('@/modules/ai/views/bot/bot-image-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botImageAttachment',
				clientAccessKeys:["/ai/bot/image-attachment"],
				serverAccessKeys:["/ai/bot/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[BotUpdate]"
		{
			path: 'update',
			name: 'ai.bot.update',
			component: () => import('@/modules/ai/views/bot/bot-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.botUpdate',
				clientAccessKeys:["/ai/bot/update"],
				serverAccessKeys:["/ai/bot/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}