//GeneratorFlag:[NoChange]
export default {
	path: 'discount',
	name: 'ai.discount.layout',
	redirect: to => {
		return {name: 'ai.discount.list'};
	},
	component: () => import('@/modules/ai/views/layout.vue'),
	meta: {
		requiresAuth: true,
		serverAccessKeys:[],
		clientAccessKeys:["/ai/discount"],
		resourceKey: 'ai.discountLayout',
		menuConfig: {
			isVisible: true,
			iconName: 'bi-link-45deg',
		}
	},
	children: [
//GeneratorFlag:[DiscountCreate]"
		{
			path: 'create',
			name: 'ai.discount.create',
			component: () => import('@/modules/ai/views/discount/discount-create.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountCreate',
				clientAccessKeys:["/ai/discount/create"],
				serverAccessKeys:["/ai/discount/create","/ai/discount/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountCreateImport]"
		{
			path: 'create-import',
			name: 'ai.discount.createImport',
			component: () => import('@/modules/ai/views/discount/discount-create-import.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountCreateImport',
				clientAccessKeys:["/ai/discount/create-import"],
				serverAccessKeys:["/ai/discount/create","/ai/discount/stream-create"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountDelete]"
		{
			path: 'delete',
			name: 'ai.discount.delete',
			component: () => import('@/modules/ai/views/discount/discount-delete.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountDelete',
				clientAccessKeys:["/ai/discount/delete"],
				serverAccessKeys:["/ai/discount/delete","/ai/discount/stream-delete"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountDetail]"
		{
			path: 'detail',
			name: 'ai.discount.detail',
			component: () => import('@/modules/ai/views/discount/discount-detail.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountDetail',
				clientAccessKeys:["/ai/discount/detail"],
				serverAccessKeys:["/ai/discount/detail"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountList]"
		{
			path: 'list',
			name: 'ai.discount.list',
			component: () => import('@/modules/ai/views/discount/discount-list.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountList',
				clientAccessKeys:["/ai/discount/list"],
				serverAccessKeys:["/ai/discount/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountAttachment]"
		{
			path: 'attachment',
			name: 'ai.discount.attachment',
			component: () => import('@/modules/ai/views/discount/discount-attachment.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountAttachment',
				clientAccessKeys:["/ai/discount/attachment"],
				serverAccessKeys:["/ai/discount/list"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[DiscountUpdate]"
		{
			path: 'update',
			name: 'ai.discount.update',
			component: () => import('@/modules/ai/views/discount/discount-update.vue'),
			meta: {
				requiresAuth: true,
				resourceKey: 'ai.discountUpdate',
				clientAccessKeys:["/ai/discount/update"],
				serverAccessKeys:["/ai/discount/update"],
				menuConfig: {
					isVisible: false
				}
			}
		},
//GeneratorFlag:[NewRoute]
	]
}