<template>
	<div ref="EditorRef" style="min-height:350px;"></div>
</template>
<script>
	import { guid } from "@/plugins"
	import ClassicEditor from '/public/lib/ckeditor/ckeditor.js'

	let codeBlocks = {};
	export default {
		emits: ['change', 'keyup'],

		props: {
			prop: {
				type: Object
			},

			'languageDirection': {
				type: String,
				default: undefined
			},
		},

		data()
		{
			return {
				value: null,
				blockId: guid.newGuid()
			}
		},

		computed:
		{
			direction()
			{
				if (this.$store.state.app.direction == 'ltr' || this.languageDirection == 'ltr')
				{
					return 'ltr'
				}

				return this.prop.display.direction;
			}
		},

		mounted()
		{
			ClassicEditor
				.create(this.$refs.EditorRef, {
					removePlugins: ['Title']
				})
				.then(editor =>
				{
					codeBlocks[this.blockId] = editor

					if (this.prop.value)
					{
						codeBlocks[this.blockId].setData(this.prop.value);
					}
					else
					{
						codeBlocks[this.blockId].setData('<div></div>');
					}

					codeBlocks[this.blockId].model.document.on('change:data', (e,v) =>
					{
						this.onHandelChange(codeBlocks[this.blockId].getData())
					});


					codeBlocks[this.blockId].editing.view.change(writer =>
					{
						writer.setStyle('min-height', '200px', codeBlocks[this.blockId].editing.view.document.getRoot());
					});
				})
				.catch((e) =>
				{
					console.log(e)
				});
		},

		methods: {

			onHandelChange(value)
			{
				this.$emit('change', value)
			},

			onHandelKeyUp()
			{
				this.$emit('keyup', this.value)
			}
		}
	}
</script>
<style>
	.ck-button__label {
		display: none !important;
	}
</style>
