<template>
	<kaveh-content width="800px" v-if="entity">
		<kaveh-form :key="ver" :entity="entity" :task="submit" />
	</kaveh-content>
</template>
<script>
	import { BehPardakhtProvider, GoogleBillingProvider } from './data/providers'
	import { PaymentProviderService } from '@/modules/finance/services'
	export default {
		data()
		{
			return {
				ver: 0,
				type: this.$route.query.type
			}
		},
		computed: 
		{
			entity()
			{
				switch (this.type)
				{
					case 'beh-pardakht':
						return BehPardakhtProvider
					case 'google-billing':
						return GoogleBillingProvider
					default:
						return undefined
				}
			},

			service()
			{
				return new PaymentProviderService()
			},
		},

		beforeMount()
		{
			if (!this.type)
			{
				this.$router.push('/')
			}
		},

		methods:
		{
			async submit(payload)
			{
				try
				{
					var editedPayload = {
						title: payload.title,
						providerKey: this.type,
						currencyUnitId: payload.currencyUnitId,
						jsonConfig: this.getJsonConfig(payload),
					}

					const res = await this.service.create(editedPayload);
					if (res.status == 200)
					{
						this.ver += 1
					}

				} catch (e) { console.log(e) }
			},

			getJsonConfig(value)
			{
				var clone =
					JSON.parse(JSON.stringify(value))


				delete clone.title;
				delete clone.providerKey;
				delete clone.currencyUnitId;

				return JSON.stringify(clone, null, "\t");;
			},
		}
	}
</script>
