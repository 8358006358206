//GeneratorFlag:[NoChange]
import { Server } from '@/core/server'
export default class PushNotificationDeviceService
{
	constructor(){}

	server(config)
	{
		return new Server(config)
	}

	//GeneratorFlag:[PushNotificationDevice.Create]
	async create(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/notification/push-notification-device/create', params || { });
	};

	//GeneratorFlag:[PushNotificationDevice.Destroy]
	async destroy(params, config)
	{
		config = config || {};
		if (config.HSR == undefined) config.HSR = true;
		if (config.HER == undefined) config.HER = true;
	
		const _server = 
			this.server(config);
	
		return await _server.post('/notification/push-notification-device/destroy', params || { });
	};

//GeneratorFlag:[NewAction]
}