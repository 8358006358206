<!--GeneratorFlag:[NoChange]-->
<template>
	<kaveh-table :task="getList" :entity="entity" :actions="actions" :filter-entity="filterEntity" />
</template>
<script>
	import { Prompt, PromptFilter } from '@/modules/ai/data/prompt/list'
	import { PromptService } from '@/modules/ai/services'
	export default {
		computed:
		{
			entity()
			{
				return Prompt;
			},
			
			filterEntity()
			{
				return PromptFilter;
			},

			service()
			{
				return new PromptService();
			},

			actions()
			{
				return [
					{
						routeName: 'ai.prompt.create',
						resourceKey: 'action.create',
						iconName: 'bi-plus-circle',
						activeMode: 'always',
					},
					{
						routeName: 'ai.prompt.createImport',
						resourceKey: 'action.import',
						iconName: 'bi-upload',
						activeMode: 'always',
					},
					{
						routeName: 'ai.prompt.attachment',
						resourceKey: 'action.fileAttachment',
						iconName: 'bi-cloud-arrow-up',
						activeMode: 'single',
						dataTransferMode: 'store',
					},
					{
						routeName: 'ai.prompt.imageAttachment',
						resourceKey: 'action.imageAttachment',
						iconName: 'bi-images',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'ai.prompt.update',
						resourceKey: 'action.update',
						iconName: 'bi-pen',
						activeMode: 'single',
						dataTransferMode: 'store',
					},

					{
						routeName: 'ai.prompt.delete',
						resourceKey: 'action.delete',
						iconName: 'bi-trash',
						activeMode: 'multiple',
						dataTransferMode: 'store',
					},
					{
						resourceKey: 'action.more',
						iconName: 'bi-arrow-down-short',
						activeMode: 'single',
						children: [{
							routeName: 'ai.prompt.detail',
							resourceKey: 'ai.promptDetail',
							iconName: 'bi-list-columns',
							activeMode: 'single',
							dataTransferMode: 'store',
						}]
					},

				]
			}
		},
		methods:
		{
			async getList(payload)
			{
				return await this.service.list(payload);
			}
		}
	}
</script>