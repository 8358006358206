import store from '@/core/store'
import ServerUtility from './server-utility'
import * as signalR from '@microsoft/signalr'

export default class WS
{
	constructor()
	{
		this.servers = store.state.server.servers;
	}

	override(connection)
	{
		connection.handle = (method, callback) =>
		{
			const _method =
				method.toLowerCase();

			if (connection._methods[_method])
			{
				connection._methods[_method] = undefined;
			}

			connection.on(method, callback)
		}
	}

	getConnection(path)
	{
		if (window.$keys.ws == undefined)
		{
			window.$keys.ws = {}
		}

		return new Promise(async (resolve, reject) =>
		{
			const utility = new ServerUtility();
			const serverConfig = utility.getServerConfig(path);

			const get = () =>
			{
				const serverName = serverConfig.name;
				const socket = store.state.server.socket;

				if (socket[serverName])
				{
					return socket[serverName]
				}

				return undefined;
			}

			const build = (serverConfig) => new Promise((resolve, reject) =>
			{
				if (serverConfig.useWs)
				{
					window.$keys.ws[serverConfig.name] = 1

					const token = store.state.client.token;
					const branchId = store.state.client.branchId;
					const personId = store.state.client.id;

					const url = `${serverConfig.host}sokets?branch-id=${branchId}`;
					const config = {
						useDefaultCredentials: true,
						accessTokenFactory: () => token
					}

					var connection =
						new signalR.HubConnectionBuilder().withUrl(url, config).build();

					connection.onclose(() =>
					{
						window.$keys.ws[serverConfig.name] = undefined;
						store.state.server.socket[serverConfig.name] = undefined;
					});

					connection.start().then(async () =>
					{
						this.override(connection)

						await store.dispatch('server/setSocket', {
							connection,
							serverName: serverConfig.name
						});

						resolve(connection)

					}).catch((e) =>
					{
						window.$keys.ws[serverConfig.name] = undefined;
						store.state.server.socket[serverConfig.name] = undefined;
						reject(e)
					})
				}
			})

			const wait = () => new Promise((resolve) =>
			{
				setTimeout(() => { resolve(true) }, 100);
			})

			try
			{
				var conn = undefined;

				do
				{
					conn = get();

					if (conn != undefined)
					{
						resolve(conn);
					}
					else
					{
						if (window.$keys.ws[serverConfig.name] == undefined)
						{
							conn = await build(serverConfig);
						}
					}


					if (conn != undefined)
					{
						resolve(conn);
					}
					else
					{
						await wait();
					}

				} while (conn == undefined);

			} catch (e)
			{
				utility.showErrorMessage(e)
				reject(e)
			}
		})
	}
}
