//GeneratorFlag:[NoChange]
import FinancialDocumentService from './financial-document-service'; //GeneratorFlag:[FinancialDocument]
import PaymentProviderService from './payment-provider-service'; //GeneratorFlag:[PaymentProvider]
import PaymentStatusService from './payment-status-service'; //GeneratorFlag:[PaymentStatus]
import TransferMethodService from './transfer-method-service'; //GeneratorFlag:[TransferMethod]
import OnlinePaymentService from './online-payment-service'; //GeneratorFlag:[OnlinePayment]
//GeneratorFlag:[NewImport]
export default {
FinancialDocumentService, //GeneratorFlag:[FinancialDocument]
PaymentProviderService, //GeneratorFlag:[PaymentProvider]
PaymentStatusService, //GeneratorFlag:[PaymentStatus]
TransferMethodService, //GeneratorFlag:[TransferMethod]
OnlinePaymentService, //GeneratorFlag:[OnlinePayment]
//GeneratorFlag:[NewExport]
}
export{
FinancialDocumentService, //GeneratorFlag:[FinancialDocument]
PaymentProviderService, //GeneratorFlag:[PaymentProvider]
PaymentStatusService, //GeneratorFlag:[PaymentStatus]
TransferMethodService, //GeneratorFlag:[TransferMethod]
OnlinePaymentService, //GeneratorFlag:[OnlinePayment]
//GeneratorFlag:[NewExport]
}
